import { withLDProvider } from "launchdarkly-react-client-sdk"

import { getUserDevice } from "../../src/utils/helpers"

import { useLayoutEffect } from "react"

const React = require("react")
const { useState, createContext, useContext } = require("react")

const { RES } = require("utils/constants")

const ldClientID = process.env.GATSBY_LAUNCHDARKLY_CLIENT_ID

export const DEVICE = {
  mobile: "mobile",
  tablet: "tablet",
  desktop: "desktop"
}

export const ORIENTATION = {
  portrait: "portrait",
  landscape: "landscape"
}

const QUERY_MOBILE = `(max-width: ${RES._512}px)`
const QUERY_TABLET = `(min-width: ${RES._512}px) and (max-width: ${RES._768}px)`
const QUERY_DESKTOP = `(min-width: ${RES._768}px)`
const QUERY_PORTRAIT = `(orientation: portrait)`

export const Context = createContext({
  device: null,
  orientation: null
})

export const useBrowserData = () => {
  const { device, orientation } = useContext(Context)

  return {
    isMobile: device === DEVICE.mobile,
    isTablet: device === DEVICE.tablet,
    isDesktop: device === DEVICE.desktop,
    isPortrait: orientation === ORIENTATION.portrait
  }
}

export const wrapPageElement = ({ element }) => {
  const [device, setDevice] = useState(DEVICE.mobile)
  const [orientation, setOrientation] = useState(DEVICE.mobile)

  useLayoutEffect(() => {
    const mobileMediaQuery = window.matchMedia(QUERY_MOBILE)
    const tabletMediaQuery = window.matchMedia(QUERY_TABLET)
    const desktopMediaQuery = window.matchMedia(QUERY_DESKTOP)
    const portraitMediaQuery = window.matchMedia(QUERY_PORTRAIT)

    mobileMediaQuery.addEventListener("change", ({ matches }) => matches && setDevice(DEVICE.mobile))
    tabletMediaQuery.addEventListener("change", ({ matches }) => matches && setDevice(DEVICE.tablet))
    desktopMediaQuery.addEventListener("change", ({ matches }) => matches && setDevice(DEVICE.desktop))
    portraitMediaQuery.addEventListener("change", ({ matches }) => setOrientation(matches ? ORIENTATION.portrait : ORIENTATION.landscape))

    if (window.matchMedia(QUERY_MOBILE).matches) setDevice(DEVICE.mobile)
    if (window.matchMedia(QUERY_TABLET).matches) setDevice(DEVICE.tablet)
    if (window.matchMedia(QUERY_DESKTOP).matches) setDevice(DEVICE.desktop)
    setOrientation(window.matchMedia(QUERY_PORTRAIT).matches ? ORIENTATION.portrait : ORIENTATION.landscape)

    return () => {
      mobileMediaQuery.removeEventListener("change", ({ matches }) => matches && setDevice(DEVICE.mobile))
      tabletMediaQuery.removeEventListener("change", ({ matches }) => matches && setDevice(DEVICE.tablet))
      desktopMediaQuery.removeEventListener("change", ({ matches }) => matches && setDevice(DEVICE.desktop))
      portraitMediaQuery.removeEventListener("change", ({ matches }) => setOrientation(matches ? ORIENTATION.portrait : ORIENTATION.landscape))
    }
  }, [])

  return <Context.Provider value={{ device, orientation }}>{element}</Context.Provider>
}

export const wrapRootElement = ({ element }) => {
  const url = window.location.pathname.slice(1)

  const pathCountry = url.split("/")[0].toUpperCase()

  const App = () => element

  const LDApp = withLDProvider({
    clientSideID: ldClientID,
    context: {
      kind: "user",
      anonymous: true,
      country: pathCountry,
      device: getUserDevice(),
      slug: `${window.location.origin}${window.location.pathname}`
    }
  })(App)

  if (ldClientID) return <LDApp />

  return element
}
