export const RES = {
  _512: 512,
  _768: 768,
  _1024: 1024
}

// TODO: Move this to a new useCookieConsent hook later on.
export const COOKIE = {
  isCookiePolicyAccepted: "isCookiePolicyAccepted"
}

export const COOKIE_CONSENT_TYPE = {
  simple: "simple",
  managed: "managed"
}

export const SUPPORTED_LANGUAGES = {
  at: "de_AT",
  br: "pt_BR",
  ca: "en_CA",
  ch: "de_CH",
  cl: "es_CL",
  dk: "da_DK",
  se: "sv_SE",
  hl: "en",
  ie: "en_IE",
  jp: "en",
  in: "en",
  nz: "en",
  pe: "es_PE",
  fi: "fi_FI"
}
